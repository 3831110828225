@keyframes pop {
  0% {
    transform: scale(1.7);
  }
  50% {
    transform: scale(2.2);
  }
  100% {
    transform: scale(1.7);
  }
}

@keyframes loading {
  0% {
    width: 0%;
    background-color: rgb(141, 238, 170);
  }
  100% {
    width: 100%;
  }
}

.pop {
  animation-name: pop;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loading {
  animation-name: loading;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
