/* @fortawesome/free-solid-svg-icons @import url('https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap'); */

#rendered h1 {
  font-weight: 600;
  font-family: 'Nunito';
  font-size: 1.25em !important;
  font-weight: 800;
  font-family: 'Josefin Sans';
  margin-bottom: 0 !important;
}

.site-info--checks label,
.section--contact label,
.section--generator label {
  font-size: 0.8rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
}

#rendered h2 {
  font-size: 2.5em !important;
  font-family: 'Bebas Neue';
  margin-bottom: 10px;
}

#rendered h3 {
  font-weight: 300;
  font-family: 'Bebas Neue';
  text-transform: uppercase;
  color: white;
  font-size: 1.4rem;
}

#rendered h3.confirm {
  font-size: 1.5em !important;
  color: #181818;
}

#rendered h2 {
  font-size: 2rem;
}

#rendered section {
  /* padding: 1rem; */
}

#rendered input[type='radio'] {
  width: 20px !important;
  padding: 10px !important;
}

#rendered {
  padding: 3rem;
  background-color: #303030;
  margin-bottom: 100px;
  min-height: 100vh;
}

#rendered input {
  background-color: #ebebeb;
  font-size: 1rem;
  font-family: sans-serif;
  border: none;
  margin: 2px !important;
}

#rendered input:hover {
  background-color: aliceblue;
}

#rendered select {
  width: 35%;
  margin: 0.5em !important;
  height: 30px;
  line-height: 2em !important;
}

#rendered .alert {
  width: 100%;
  background-color: rgb(249, 101, 101);
  color: white;
}

#rendered .alert > span {
  font-weight: 800;
  color: rgb(94, 1, 1);
}

#rendered .logo-holder {
  background-image: url('../../../public/logo.png');
  background-attachment: local;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 1.5rem;
  background-position: top;
  min-height: 100px;
  max-width: 300px;
}

#rendered .radio {
  margin-left: 0.35rem;
}

#rendered .form--header {
  text-align: center;
  margin-top: 0.75rem;
  line-height: 0.5rem;
}
@media only screen and (max-width: 1000px) {
  #rendered {
    padding: 3%;
  }
  #rendered section.section--material label {
    font-size: 0.85em !important;
  }
}
@media only screen and (max-width: 830px) {
  #rendered main {
    padding: 0 !important;
  }
  #rendered .main-form {
    padding: 0 !important;
  }
  #rendered section {
    max-width: 100%;
    padding: 1rem;
  }
  #rendered section.section--material {
    min-height: 500px;
    display: grid;
    /* column-gap: 20px; */
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas:
      'material-section--title material-section--title'
      'material--lamps material--batteries'
      'material--ewaste material--batteries'
      'material--hazardous material--ballast'
      'material--hazardous material--specialty';
  }
  #rendered .logo-holder {
    margin-left: auto;
    margin-right: auto;
    height: 80px !important;
  }
  #rendered .container--content > div {
    margin-top: 0.25em !important;
    padding: 0em !important;
  }
}
@media only screen and (max-width: 600px) {
  #rendered {
    padding: 5px;
  }
  #rendered section.section--material {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  #rendered section {
    padding: 0rem;
  }
}
#rendered .section--title {
  width: 100%;
  height: 40px;
  color: white;
  font-family: 'Bebas Neue';
  font-size: 2rem;
  text-align: center;
  background-color: black;
}

#rendered .section--contact input,
#rendered .section--generator input {
  width: 100%;
}

#rendered .city-state {
  display: grid;
  column-gap: 5px;
  grid-template-columns: 55% 20% auto;
}

#rendered .pickup-delivery {
  margin-top: 1rem;
  font-size: 1.75rem;
}

#rendered .pickup-delivery > input {
  margin-top: 0.4em !important;
}

#rendered .pickup-delivery > label {
  margin-right: 0.55rem;
}

#rendered .main-form {
  max-width: 960px !important;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  /* border: 1px solid black; */
  padding: 2rem;
}

#rendered .container--content input {
  height: 30px;
  width: 90%;
  border: none;
}

#rendered .container--content label {
  margin-top: 6px;
}

#rendered .container--content > div {
  /* margin-top: 1rem;
    margin-bottom: 1rem; */
  line-height: 1rem;
  align-items: center;
  text-align: right;
  column-gap: 10px;
  display: grid;
  grid-template-columns: 90px auto;
  padding: 1rem;
}

#rendered .section--material {
  background-color: #eeeeee;
  width: 100%;
  min-height: 600px;
  display: grid;
  /* column-gap: 20px; */
  gap: 2px;
  grid-template-columns: repeat(3, minmax(80px, 1fr));
  grid-template-rows: 40px 2fr 2fr 2fr 1.5fr;
  grid-template-areas: 'material-section--title material-section--title material-section--title' 'material--lamps material--ballast material--batteries' 'material--lamps material--hazardous material--batteries' 'material--ewaste material--hazardous material--batteries' 'material--ewaste material--specialty material--batteries';
}

#rendered section.section--material input {
  margin-top: 4px;
  height: 25px;
  border: none;
  background-color: rgb(228, 228, 228);
  border-bottom: 1px solid grey;
}
#rendered section.section--material input::placeholder {
  font-weight: bold;
  color: var(--cri-grey);
}

#rendered section.section--material label {
  height: 25px;
  font-size: 1rem !important;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  margin-top: 4px;
  margin-left: 1rem;
  margin-bottom: 2px;
}

#rendered .material-section--title {
  background-color: black;
  color: white;
  font-size: 2rem;
  font-family: 'Bebas Neue';
  text-align: center;
  grid-area: material-section--title;
}

#rendered .material--title {
  grid-column: 1/-1;
  padding-top: 6px;
  padding-left: 1rem;
  background-color: var(--cri-grey);

  font-family: Avenir, Arial, Helvetica;
  /* margin-bottom: 0.5rem; */
  /* margin-top: 0.5rem; */
}

#rendered .material--title h2 {
  font-size: 1.5rem;
  color: white;
  color: var(--cri-green);
  font-weight: 400;
}

#rendered .material--ballast {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-auto-rows: min-content;
  grid-area: material--ballast;
}

#rendered .material--haz {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-auto-rows: min-content;
  grid-area: material--hazardous;
}

#rendered .material--lamps {
  display: grid;
  grid-template-columns: 65% 35%;
  grid-auto-rows: min-content;
  grid-area: material--lamps;
}

#rendered .material--eWaste {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-auto-rows: min-content;
  grid-area: material--ewaste;
}

#rendered .material--specialty {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-auto-rows: min-content;
  grid-area: material--specialty;
}

#rendered .material--batteries {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-auto-rows: min-content;
  grid-area: material--batteries;
}

#rendered .site-info--checks {
  display: grid;
  grid-template-columns: 70% 30%;
}

#rendered .section--containers {
}

#rendered .section--site-info textarea {
  width: 100%;
}

#rendered .site-info--row {
  margin-top: 1rem;
}

.rendered-section__submit {
  border-top: 1px dotted grey;
  position: fixed;
  bottom: 0%;
  z-index: 100;
  background-color: rgb(245, 245, 245);
  height: 100px;
  width: 100%;
  display: flex;
  margin: auto;

  align-items: center;
  justify-content: center;
}

.rendered-section__submit > button#submit-form {
  border: none;
  min-width: 150px;
  margin: 1rem;
  padding: 0.5rem;
  background-color: var(--cri-green);
  background-color: rgb(80, 208, 0);
  color: white;
  font-size: 1.25rem;
}

.rendered-section__submit > button#go-back {
  border: none;
  margin: 1rem;
  min-width: 150px;
  padding: 0.5rem;
  background-color: var(--cri-blue2);
  color: white;
  font-size: 1.25rem;
}

/*-------------------------------------------------
                  Loading
  --------------------------------------------------*/

#rendered .loading__container {
  margin-top: 2rem;
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

#rendered .loading--holder {
  position: relative;
  border-radius: 50px;
  width: 80%;
  height: 16px;
  background-color: grey;
}

#rendered .loading--progress {
  position: absolute;
  border-radius: 50px;
  transition: all 0.2s ease-in;
  height: 16px;
  background-color: rgb(23, 218, 81);
  animation: loading 0.8s infinite cubic-bezier(0.68, -0.55, 0.265, 1.25);
}

@keyframes loading {
  0% {
    width: 0%;
    background-color: rgb(141, 238, 170);
  }
  100% {
    width: 100%;
  }
}
