/* @font-face {
  font-family: "Nucleo Outline";
  src: url("../fonts/nucleo-outline.eot");
  src: url("../fonts/nucleo-outline.eot") format("embedded-opentype"),
    url("../fonts/nucleo-outline.woff2") format("woff2"),
    url("../fonts/nucleo-outline.woff") format("woff"),
    url("../fonts/nucleo-outline.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */
/* @fortawesome /free-solid-svg-icons @import url('https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap');  */
@import url('https://fonts.googleapis.com/css?family=Nunito:400,400i,600,600i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css?family=Roboto');
/*------------------------
  base class definition
  -------------------------*/

:root {
  --selected: rgb(25, 148, 242);
  --cri-blue: rgb(25, 148, 242);
  --cri-green: rgb(80, 208, 0);
  --cri-green2: rgb(0, 157, 5);
  --cri-blue2: rgb(0, 157, 193);
  --cri-grey: rgb(55, 55, 55);
  --cri-error: tomato;
  --cri-sub-green: green;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:hover {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.roboto {
  font-family: roboto;
}

.now-ui-icons {
  display: inline-block;
  font: normal normal normal 14px/1 'Nucleo Outline';
  font-size: inherit;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

html,
body {
  font-family: roboto;
  font-size: 16px;
}

h1 {
  font-weight: 600;

  font-size: 1.25em !important;
  font-weight: 800;
  font-family: 'Josefin Sans';

  text-transform: capitalize;
}

main#rendered h2 {
  font-size: 2.5em;
  font-family: 'Bebas Neue';
  margin-bottom: 10px;
  text-transform: capitalize;
}

h3 {
  font-weight: 600;
  font-family: Avenir, Arial, Helvetica;
  color: #1c87c9;
  text-transform: capitalize;
}

body {
  min-height: 100vh;
  position: relative;
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  overflow-x: hidden;
}

select {
  margin: 0;
  outline: none;
  box-sizing: border-box;
  padding: 5px;
  background: rgb(244, 244, 244) !important;
  border: none;
  border-bottom: 1px solid #eee;
  font-family: sans-serif;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
main#rendered {
  font-family: roboto;
  font-size: 12px;
}
main#rendered input {
  width: 100%;
  position: relative;
  font-size: 1.45em;
  padding: 5px;
  background: rgb(244, 244, 244);
  border: none;
  transition: background-color 0.2s ease;
  font-family: sans-serif;
  color: var(--cri-grey);
}

main#rendered input::placeholder {
  color: #282828;
}

main#rendered input.required::placeholder {
  color: red;
  font-weight: 500;
}

main#rendered input[type='checkbox'] {
  /* -webkit-appearance: none; */
  font-family: Impact, 'Arial Narrow Bold', sans-serif;
  color: var(--cri-green);
  width: 25px;
  height: 25px;
  margin: 5px;
  background: rgb(176, 176, 176);
  border-radius: 5px;
}

/* input[type='checkbox']:checked {
    background: rgb(222, 222, 222);
  }

  input[type='checkbox']:checked:after {
    content: "+";
    position: absolute;
    transform: rotate(45deg)translate(-24.5px, -20.5px);
    font-size: 59px;
  } */

input::placeholder {
  color: #b6b6b6;
}

main#rendered form {
  padding: 15px;
  /* border: 1px solid rgb(36, 36, 36); */
  background-color: #fff;
  height: inherit;
}

main#rendered input.withLabel {
  /* width: 100% !important; */

  height: 40px;
  margin-top: 5px;
  font-size: 1.35rem;
  margin: none;
  left: 0;
  padding: none;
  line-height: 2px;
}

main#rendered button {
  display: inline;
  width: 130px;
  padding: 10px;
  margin: 0px auto 0;
  border: none;
  border-radius: 5px;
  background-color: #1c87c9;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 600;
  color: #fff;
  transition: background-color 0.5s ease;
}

main#rendered button:hover {
  background-color: #0789da;
}

main#rendered button:disabled {
  background-color: var(--cri-grey) !important;
  cursor: not-allowed;
}

main#rendered button.greyed-out {
  background-color: var(--cri-grey) !important;
  cursor: not-allowed;
}

main#rendered option {
  border: none;
  padding: 4px;
}

main#rendered .other-comments {
  text-align: left;
  font-size: 1.75rem;
  font-weight: 800;
}
main#rendered .textarea {
  border: 1px solid #f7f7f7;
  border-radius: 10px;
  background-color: #f0f0f0;
  font-family: roboto;
  width: 90% !important;
  margin: 0 0 1rem 0;
  min-height: 120px;
  padding: 10px;
}

/*------------------------
    HEADER
  -------------------------*/

.title {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.logo-holder {
  /* background-image: url('../../public/logo.png'); */
  background-attachment: local;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 1.5rem;
  background-position: top;
  min-height: 100px;
  max-width: 300px;
}

.order-form-title {
  margin-top: 1rem;
  text-align: center;
  margin: 0;
  line-height: auto;
}
.order-form-title > p {
  margin-bottom: 0;
}

.header-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header--span {
  font-family: 'Roboto';

  line-height: 1.75rem;
  margin: 10px 0;
  font-weight: 800;
  font-size: 1rem;
}

.order-form-title h2 {
  margin: 0;
  padding: 0;
  line-height: 1em;
  letter-spacing: 1px;
  font-size: 1.75rem;
}

/*-------------------------------------------------
                      PROGRESS NODES
  --------------------------------------------------*/

.progress-nodes {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
  margin-top: 2rem;
  width: 90%;
  position: relative;
}

.progress-nodes--completed {
  border-radius: 8px;
  background-color: var(--cri-green);
  width: 0%;
  transition: width 0.5s ease;
  transform: translateY(6px);
  height: 6px;
  position: relative;
  z-index: 2;
  margin: 0;
  padding: 0;
}

.progress-nodes > ul {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.progress-nodes > ul > li {
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  background-color: var(--cri-green);
  border-radius: 100%;
  transform-origin: center;
  transition: transform 0.2s ease;
  z-index: 100;
  cursor: pointer;
}

.progress-nodes > ul > li > a {
  position: absolute;
  text-decoration: none;
  font-weight: 400;
  text-align: center;
  transform-origin: center;
  transition: transform 0.2s ease;
  color: grey;
  transform: translate(-25%, 1.75em) scale(0.8);
}

.progress-nodes > ul > li > div {
  position: absolute;
  transform: translate(3px, 0px);
  color: white;
  font-size: 0.9em;
}

.progress-nodes > ul > li.current {
  transform: scale(1.7);
  background-color: var(--cri-green);
  transition: transform 0.2s ease;
}

.progress-nodes > ul > li.current > a {
  transform: translate(-35%, -1.5rem) scale(1);
  transition: transform 0.2s ease;
  font-weight: 800;
  color: var(--cri-grey);
}

ul.focused {
  transition: width 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-color: var(--selected) !important;
  z-index: 100;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex !important;
  position: relative !important;
  visibility: visible !important;
}

.big-list-hide {
  opacity: 1;
}

.sub-item-d-none {
  display: none !important;
}

ul.sub-item--ul {
  list-style: none;
  position: absolute;
  display: flex;
  /* transition-delay: display 3s; */
  height: 6px;
  z-index: 100;
  border-radius: 6px;
  width: 1%;
  transition: width 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  justify-content: space-around;
  visibility: hidden;
}

.sub-item--ul a {
  position: absolute;
  z-index: 100;
  transform-origin: center;
  font-size: 0.2rem;
  font-weight: 600;
  transform: translateX(-10px);
  color: black;
  margin-top: 35px;
  text-align: center;
}

.sub-item--ul > li {
  visibility: hidden;
}

.sub-item--ul > li.current {
  transform: scale(1.2);
  background-color: var(--cri-green);
  transition: transform 0.2s ease;
}

.sub-item--ul > li.current a {
  transform: translate(-13px, -7px);
  transition: transform 0.2s ease;
  width: 50px;
  color: var(--cri-grey);
}

ul.focused > li > a {
  font-size: 1rem;
}

.focused > li {
  visibility: visible;
}

li.sub-item {
  margin: 0;
  padding: 0;
  width: 15px;
  height: 15px;
  background-color: var(--cri-blue2);

  border-radius: 100%;
  cursor: pointer;
  z-index: 100;
}

li.sub-item a {
  color: grey;
  background-color: none;
}

/*-------------------------------------------------
                      FORM SECTIONS
  --------------------------------------------------*/

.main-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.main-block {
  position: relative;
  height: 100%;
  max-width: 836px;
  min-width: 835px;
  padding: 2rem;
  background: #f7f7f7;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8rem;
}

.main-form {
  background-color: #303030;
  min-height: 100vh;
}

.title-of-section {
  /* background-color: #272731; */
  background: rgb(3, 3, 4);
  background: radial-gradient(
    circle,
    rgba(3, 3, 4, 1) 37%,
    rgba(57, 54, 60, 1) 100%
  );
  color: #fff;
  height: 54px;
  text-align: center;
  padding-top: 7px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  z-index: 50;
}
.title-of-section > h2 {
  font-size: 2rem;
  font-family: Avenir, Arial, Helvetica;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.form-values {
  background-color: #fff;
  min-height: inherit;
  padding-bottom: 40px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.divider {
  width: 100%;
  background: linear-gradient(to top right, #39363c 0%, #000000 100%);
  color: #fff;
  text-align: center;
  font-size: 1.45em;
  font-weight: 400;
  min-height: 40px;
  margin-bottom: 40px;
}

.divider span {
  vertical-align: middle !important;
}

.input--wrapper > * {
  margin-bottom: 7px;
}

.divider2 {
  margin-top: 1em;
  margin-bottom: 5px;
  width: 100%;
  background: #867f8d;
  color: #fff;
  text-align: center;
  padding: 1px;
  font-size: 1.5rem;
  font-weight: 500;
  height: auto;
}

/*-------------------------------------------------
                      ORDER SECTION
  --------------------------------------------------*/

.order-type--box {
  margin-top: 2.5rem;
  width: inherit;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  justify-content: space-around;
}
.order-type--button {
  min-width: 200px;
}
.order-type--box input {
  display: none;
}

.order-type--box label {
  border-radius: 5px !important;
  font-size: 1rem;

  min-height: 160px;
  letter-spacing: 1px;
  padding: 1rem;
  font-family: roboto;
  font-weight: bold;
  text-transform: uppercase;
  /* width: 45%; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 10px -8px;
  background-color: ghostwhite;
  color: #181818;
}

.order-type--box label:hover {
  background-color: rgb(232, 232, 242);
}

.order-type--box input:checked + .order-type--label {
  background-color: var(--selected);
  transition: background-color 0.5s;
  color: white;
}

.order-type--label .text {
  max-width: 200px;
}

.order-type--label i {
  font-size: 2rem;
  margin-bottom: 2rem;
  display: block;
}

.locations-area {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.locations-area h2.title {
  font-size: 2rem;
  grid-column: 1/-1;
  margin: 1rem auto;
}

.locations-area div {
  font-size: 0.85rem;
}

.upload-pdf-form {
}

.upload-pdf-form::-webkit-file-upload-button {
  background-color: var(--selected);
  border: none;
  color: white;
}

/*-------------------------------------------------
                      BILLING / GENERATOR
  --------------------------------------------------*/

.city-state {
  display: grid;
  column-gap: 5px;
  grid-template-columns: 55% 20% 23%;
}

/*-------------------------------------------------
                      MATERIALS
  --------------------------------------------------*/

.divide {
  padding-top: 10px;
  padding-left: 10px;
  display: grid;
  grid-template-columns: 75% 25%;
  /* margin-top: 0px;
  border-top: 5px solid #50e21b; */
}

.divide i {
  margin-right: 0.75rem;
}

.material-holder {
  background: linear-gradient(to top right, #4f7f45 0%, #ccff66 100%);
  padding-top: 10px;
  /* margin-top: 8px; */
  position: relative;
  transition: width 0.2s ease;
}

.material {
  background-color: #fff;
  /* padding-top: 10px; */
}

.material-picker--wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
}

.material-picker--wrapper > p {
  text-align: center;
}

.material-picker--wrapper p {
  grid-column: 1/-1;
  max-width: 450px;
  padding: 0.75em;
  margin: auto;
  line-height: 1em;
  font-size: 1.5em;
}

.material-picker--selector {
  display: flex;
  justify-content: center;
  margin: 1em;
  height: 120px;
}

.material-picker--selector label {
  background-color: aliceblue;
  transition: background-color 0.2s ease;
  text-align: center;
  justify-self: center;
  /* max-width: 80%; */
  margin: 0;
  padding: 10px;
  width: 100%;
  min-width: 150px;
  /* max-width: 151px; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  cursor: pointer;
}

.material-picker--selector input {
  display: none;
}

.material-picker--selector input:checked + label {
  background-color: var(--cri-grey);
  color: white;
  transition: background-color 0.2s ease;
  user-select: none;
}

.label-input-quantity {
  display: grid;
  grid-template-columns: 40% 30% 25%;
  grid-auto-rows: 55px;
  align-items: center;
}

.bold-label {
  font-size: 1.25rem;
  line-height: 20px;
  margin: 0;
  max-width: 85px;
  margin: auto;
  text-align: right;
  margin-right: 10px;
  font-weight: 400 !important;
}

.units--holder {
  display: inline-flex;
  border-radius: 30px;
  height: 40px;

  margin-left: 1rem;
  width: 70px;
  /* border: 1px solid rgb(255, 61, 61); */
  background-color: rgb(26, 26, 26);
}

.units--holder input {
  display: none;
  cursor: pointer;
}

.units--holder label {
  font-size: 1.25rem;
  color: white;
  margin-top: 6px;
  margin-left: 6px;
  border-radius: 25px;
  width: 60px;
  font-weight: 400;
  height: 30px;
  cursor: pointer;
  display: inline-block;
  z-index: 100;
}

.units--holder > span {
  margin-top: 4px;
  height: 32px !important;
  width: 32px !important;
  border-radius: 100%;
  display: block;
  position: absolute;
  background-color: var(--selected);
  transform: translateX(34px);
  transition: transform 0.2s;
}

.units--holder input:checked ~ span {
  background-color: var(--cri-green2);
  transform: translateX(3px);
}

/*-------------------------------------------------
                      CONTAINERS
  --------------------------------------------------*/

.container-box > div {
  display: grid;
  grid-template-columns: 40% 60%;
  /* column-gap: 10px; */
  align-items: center;
}

.container-box input {
  width: 100%;
  margin-left: 1rem;
}

/*-------------------------------------------------
                      SITE INFO
  --------------------------------------------------*/

.site-info {
  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-template-rows: 110px repeat(3, 85px);
  align-items: flex-start;
  /* padding: 0 !important; */
}

.time-select {
  width: 100px !important;
  margin-left: 5px !important;
  margin-right: 5px;
  height: 30px;
  font-size: 0.9em;
  font-weight: 600;
  right: 0 !important;
}

.pickup-details {
  width: 100%;
  margin-bottom: 0.4em;
}

.ad-opt {
  align-self: flex-start;
  margin-top: 1em;
  margin-bottom: 10px;
  height: 30px;
  font-size: 1.75rem;
  line-height: 1em;
  text-align: left;
  font-weight: 800 !important;
}

.site-info :first-child {
  grid-column: 1/-1;

  line-height: 1em;

  font-weight: 800 !important;
}

.site-info input {
  justify-self: center;
  display: none;
}

.site-info label:hover {
  opacity: 0.8;
}

.site-info h5 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 200;
  margin-top: 0.35em;
}

.site-info label {
  width: 93%;
  height: 75px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  text-align: center !important;
  font-size: 60%;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.2s ease;
  background-color: aliceblue;
  /* border: 1px solid grey; */
  box-shadow: 0px 0px 5px 0px grey;
}

.site-label {
  margin-top: 1em;
  margin-bottom: 10px;
  height: 30px;
  font-size: 1.75rem;
  line-height: 1em;
  text-align: left;
  font-weight: 800 !important;
}

.site-info-check:checked + label {
  background-color: var(--cri-grey);
  color: white;
}

.items-notice {
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
  font-size: 1.15em;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  color: white;
  background: rgb(231, 74, 74);
}

.label-time {
  font-weight: 600;
}

#file_upload_area {
  transition: all 0.2s ease;
}
#filesUploader {
  background: #e7e7e7;
  font-size: 2em;
}

#filesUploader::-webkit-file-upload-button {
  background-color: var(--selected);
  color: white;
  border: none;
  padding: 0.5em;
}

/*-------------------------------------------------
                      ERRORS AND VALIDATION
  --------------------------------------------------*/

.hidden {
  display: none;
}

#btn-submit {
  height: 50px;
  background-color: var(--cri-green);
}

#btn-submit--grey {
  height: 50px;
  background-color: var(--cri-grey);
}

.invalid {
  transition: background-color 0.2s ease;
  background-color: rgba(255, 0, 0, 0.25);
}

.verify {
  transform: translate(0px, -4px);
  height: 3px;
  width: 0px;
  transition: width 0.3s ease;
  background-color: var(--cri-green);
  z-index: 100;
}

.label-input-quantity input:focus ~ div.verify {
  background-color: var(--cri-green);
  width: 100%;
}

input:focus + div.verify {
  background-color: var(--cri-green);
  width: 100%;
}

.error-display {
  background-color: red;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1em;
  z-index: 1;
  position: relative;
  margin-bottom: -40px;
  transform: translateY(-40px);
  opacity: 0;
  transition: transform 0.2s ease;
}

.error-show {
  z-index: 1;
  opacity: 1;
  transform: translateY(10px);
}

/*-------------------------------------------------
                  UTILITY
  --------------------------------------------------*/

div.big-button {
  margin-top: 1em;

  font-size: 1em;
  letter-spacing: 1px;

  font-weight: 200;
  line-height: auto;
  text-align: center;
  padding-top: 0.75rem;
  height: 50px;
  position: relative;
  width: 100%;
  font-size: 1.25rem;
  box-shadow: 0 0px 2px 1px gray;
  background-color: ghostwhite;
}

.big-button > label {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.big-button i {
  margin-right: 1rem;
}

.big-button-input {
  align-self: center;
  position: absolute;
  visibility: hidden;
}

.big-button-input:checked + div {
  background-color: var(--selected);
  color: white;
}

div.form-button1 {
  display: flex;
  justify-content: center;
  margin: 1em;
  height: 120px;
}

.form-button1 label {
  background-color: aliceblue;
  transition: background-color 0.2s ease;
  text-align: center;
  justify-self: center;
  margin: 0;
  padding: 10px;
  width: 100%;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  cursor: pointer;
}
.form-button1 label:hover {
  background-color: #ccecff;
}
.form-button1 input {
  display: none;
}

.form-button1 input:checked + label {
  background-color: var(--cri-grey);
  color: white;
  transition: background-color 0.2s ease;
  user-select: none;
}

/*-------------------------------------------------
                  FOOTER
  --------------------------------------------------*/

.button-section {
  display: flex;
  position: relative;
  margin: auto;
  /* flex-direction: column-reverse; */
  width: 80%;
  background-color: white;
  justify-content: space-evenly;
  align-items: center;
  padding: 2em;
  bottom: 0;
  /* margin-top: 100px; */
}

.button--wrapper {
  box-shadow: 0px 0px 10px 0px;
  background-color: white;
  position: fixed !important;
  width: 100%;
  bottom: 0;
  z-index: 100;
}

/*-------------------------------------------------
                  Loading
  --------------------------------------------------*/

.loading__container {
  height: 30vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.loading--holder {
  position: relative;
  border-radius: 50px;
  width: 100%;
  height: 16px;
  background-color: grey;
}

.loading--progress {
  position: absolute;
  border-radius: 50px;
  transition: all 0.2s ease-in;
  height: 16px;
  background-color: rgb(23, 218, 81);
  animation: loading 0.8s infinite cubic-bezier(0.68, -0.55, 0.265, 1.25);
}

#file_upload_area > div > img {
  border: 1px solid #d3d3d3;
  margin: 10px;
  max-height: 120px !important;
  position: relative;
  display: inline;
}

.img-holder {
  padding: 10px;
  position: relative;
  display: inline-block;
  height: 100%;
  width: auto;
  transition: all 0.4s ease;
}

.img-holder:nth-of-type(odd) {
  background-color: aliceblue;
}

.img-holder > p {
  font-size: 0.75em;
}
.delete-img {
  z-index: 100;
  font-size: 2em;
  line-height: 19px;
  padding-left: 3px;
  width: 25px;
  height: 25px;
  line-height: auto;
  color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 100%;
  transform-origin: center;
  transform: rotate(-45deg);
  cursor: pointer;
  background-color: var(--cri-green2);
}

.btn--disabled {
  background-color: grey !important;
}
